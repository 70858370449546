<script>
    import Icon from './Icon.svelte';
    import { img_cdn, img_cdn_srcset } from './utils/image';

    /** @type {Course | Worksheet} */
    export let product;

    let className = '';
    export { className as class };
    export let style = '';
</script>

<div
    class="relative w-24 h-24 flex-shrink-0 aspect-square bg-black rounded-full {className}"
    {style}
>
    <img
        src={img_cdn(product.icon, { w: 96, h: 96, fit: 'contain' })}
        srcset={img_cdn_srcset(product.icon, {
            w: 96,
            h: 96,
            fit: 'contain'
        })}
        alt={product.title}
        class="drop-shadow-lg {product.purchased ? 'opacity-20' : ''} w-24 h-24 object-cover aspect-square"
        loading="lazy"
    />

    {#if product.purchased}
        <Icon
            icon="check"
            xl
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"
        />
    {/if}
</div>
